@define-mixin formInput {
  margin-top: 15px;
  border-color: color( var(--color__text) a(.6));
  border-radius: .6em;
  background-color: transparent;
  font: 400 .9rem/1.2 'Century Gothic', serif;
  color: var(--color__text);

  &::placeholder {
    opacity: 1;
    color: var(--color__text);
  }

  &:focus {
    outline: 0;
  }
}
.wpcf7 {
  .wpcf7-form {
    input {
      &[type="text"],
      &[type="email"],
      &[type="tel"] {
        width: calc(100% / 3 - 10px);
        @mixin formInput;
        @media (--phone){
          width: 100%;
        }
      }

      &[type="email"],
      &[type="tel"] {
        margin-left: 15px;
        @media (--phone){
          margin-left: 0;
        }
      }

      &[type="submit"] {
        display: block;
        padding: .5em 1em;
        margin: 1em auto 0;
        background-color: var(--color__orange);
        border-color: var(--color__orange);
        text-transform: uppercase;
        font: 700 .9rem/1.2 'Century Gothic', serif;
        color: white;

        &:hover {
          background-color: transparent;
          color: var(--color__orange);
        }
      }
    }

    textarea {
      @mixin formInput;
      resize: none;
    }
  }
}
