@mixin font ssr, titilliumweb-regular;
@font-face {
  font-family: 'Century Gothic';
  src: url('../fonts/gothic-1.eot'); /* IE9+ */
  src: url('../fonts/gothic-1.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/gothic-1.woff') format('woff'), /* chrome、firefox */
  url('../fonts/gothic-1.woff2') format('woff2'), 
  url('../fonts/gothic-1.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url('../fonts/gothic-1.svg#fontname') format('svg'); /* iOS 4.1- */
}
@font-face {
  font-family: 'Century Gothic';
  font-weight: 700;
  src: url('../fonts/gothicb0-1.eot'); /* IE9+ */
  src: url('../fonts/gothicb0-1.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/gothicb0-1.woff') format('woff'), /* chrome、firefox */
  url('../fonts/gothicb0-1.woff2') format('woff2'), 
  url('../fonts/gothicb0-1.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url('../fonts/gothicb0-1.svg#fontname') format('svg'); /* iOS 4.1- */
}
