.header-clone {
  display: none;
}
.site-header {
  background-color: transparent;
  @media (--phone){
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.fixed {
    position: fixed !important;
    background-color: transparent;
  }

  &.float-header {
    background-color: var(--color__blue);
  }
}
