.about__quality {
  overflow: hidden;
  position: relative;
  z-index: 3;

  &:before {
    content: 'quality';
    position: absolute;
    top: 50%;
    right: 0;
    z-index: -1;
    transform: translate(-10%, -30%);
    @mixin bigFont;
  }
}
