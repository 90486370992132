:root {
  --color__orange: #f7941e;
  --color__blue: #144e77;
  --color__blue_dark: #10415e;
  --color__text: #144e77;
  --color__text_b: #414142;

  --zIndex__top: 9999;
  --zIndex__middle: 999;
  --zIndex__bottom: 99;
}
@define-mixin bigFont {
    font: 700 10rem/1 'Century Gothic', serif;
    color: color(white a(.1));
    text-transform: uppercase;
    @media (--phone){
      font-size: 5rem;
    }
}
