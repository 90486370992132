.about__guide {
  margin-top: 4.7em;
  margin-left: 3.5em;
  @media (--phone){
    margin-left: 1em;
  }

  &:after {
    height: 7.6em;
    @media (--phone){
      height: 7em;
    }
  }
}
