.color {
  &_orange {
    color: var(--color__orange);
  }

  &_blue {
    color: var(--color__blue_dark);
  }

  &_black {
    color: var(--color__text_b);
  }

  &_white {
    color: white;
  }
}
