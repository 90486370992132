.services {
  margin-top: -4em;
  padding-bottom: 3em;
  @media (--phone){
    margin-top: 0;
  }

  > .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    @media (--phone){
      padding-left: 1em !important;
      padding-right: 1em !important;
    }

    &:nth-child(even) {
      .services__content {
        order: -1;
        @media (--phone){
          order: unset;
        }
      }
    }

    > .wpb_column > .vc_column-inner,
    > .wpb_column > .vc_column-inner > .wpb_wrapper {
      height: 100%;
    }
  }
}
