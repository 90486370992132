.preloader {
  .pre-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15%;
    max-width: 9rem;
    transform: translate(-50%, -50%);
    animation: op 1s cubic-bezier(0.4, 0.4, 0.8, 0.8) .1s both;
  }
}

@keyframes op {
  0% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
