.banner-video {
  & &__head {
    position: absolute;
    top: 40%;
    left: 10%;
    z-index: var(--zIndex__bottom);
    transform: translateY(-50%);
    width: 80%;
  }
}
