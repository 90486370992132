.guide {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  font-size: .8rem;
  @media (--phone){
    font-size: 1rem;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 2.2em;
    height: 2.2em;
    margin-right: 1em;
    vertical-align: middle;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 1.1em;
    width: 1px;
    height: 3em;
    background-color: var(--color__orange);
    transform: translateY(-100%);
  }
}

@each $i in idea, tool {
  .guide_$(i) {
    &:before {
      background: url(../images/icon_$(i)@1x.png) center/contain no-repeat;
    }
  }
}
