.about-feature__item {
  margin-left: 0;
  margin-right: 0;
  padding: 0 10px;
  background-clip: content-box;
  transition: flex .4s linear;
  @media (--desktop){
    flex: 1;
  }
  @media (--ipad){
    width: 50%;

    &:nth-child(3),
    &:nth-child(4){
      margin-top: 1.2em;
    }
  }
  @media (--ipad_mini){
    width: 50%;

    &:nth-child(3),
    &:nth-child(4){
      margin-top: 1.2em;
    }
  }
  @media (--phone){
    width: 100%;

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4){
      margin-top: 1.2em;
    }
  }

  &:hover {
    @media (--desktop) {
      flex: 1.3;
    }

    > .wpb_column > .vc_column-inner > .wpb_wrapper {
      background: linear-gradient(180deg, transparent 40%, black) left bottom/100% auto no-repeat;

      &:before {
        visibility: hidden;
      }
    }
  }

  .vc_column-inner {
    padding-top: 0 !important;
  }

  .wpb_raw_html {
    margin-top: 10em;
    padding: 10px 15px;
  }

  > .wpb_column,
  > .wpb_column > .vc_column-inner,
  > .wpb_column > .vc_column-inner > .wpb_wrapper {
    height: 100%;
  }

  > .wpb_column > .vc_column-inner > .wpb_wrapper {
    overflow: hidden;
    position: relative;
    background-color: color( var(--color__blue_dark) b(+10%) );
  }
}
@for $i from 1 to 4 {
  .about-feature__item:nth-child( $(i) ) > .wpb_column > .vc_column-inner > .wpb_wrapper {
    &:before {
      content: '';
      position: absolute;
      top: 30px;
      left: 10px;
      width: calc(100% - 20px);
      height: 40px;
      background: url(../images/feature_$(i)@1x.png) center no-repeat;
    }
  }
}
