.woocommerce {
  .products {
    .product-category,
    .type-product {
      margin-bottom: 4em !important;
      margin-right: 1em !important;
      @media (--phone){
        float: none;
        width: 100% !important;
        margin-bottom: 4em !important;
        margin-right: 0 !important;
      }
    }

    .product-category,
    .type-product {
      position: relative;
      width: calc(( 100% - 2em ) / 3) !important;
      padding: 1em !important;

      &.last {
        margin-right: 0 !important;
      }

      > .button.product_type_simple {
        display: none;
      }

      &:hover {
        .woocommerce-loop-category__title,
        .woocommerce-loop-product__title {
          transform: translateY(90%);
        }
      }
    }

    .product-category {
      width: calc(( 100% - 1em ) / 2) !important;
      margin-right: 1em !important;

      &.last {
        margin-right: 1em !important;
      }

      &:nth-child(2n) {
        margin-right: 0 !important;
      }

      &.last,
      &.first {
        clear: none;
      }
    }

    .product-category > a > img,
    .attachment-woocommerce_thumbnail {
      margin-bottom: 0 !important;
      object-fit: contain;
    }

    .attachment-woocommerce_thumbnail {
      height: 8em !important;
    }

    .product-category > a > img {
      height: 12em !important;
    }

    .woocommerce-loop-category__title,
    .woocommerce-loop-product__title {
      position: absolute;
      bottom: 0;
      left: 10%;
      width: 85%;
      transform: translateY(50%);
      padding-left: 1em !important;
      padding-right: 1em !important;
      background-color: white;
      text-transform: uppercase;
      font-size: 1.2rem !important;
      font-weight: 700;
      text-align: left;
      color: var(--color__blue);
      transition: transform .4s;
      
      > span {
        display: block;
        font-weight: 400;
        font-size: .8em;
      }
    }

    .woocommerce-loop-category__title {
      text-align: center;
    }

    .woocommerce-loop-category__title > .count {
      display: none;
      visibility: hidden;
    }
  }

  /* WOOCOMMERCE COMMONS */
  &.woocommerce-page {
    .woocommerce-breadcrumb,
    #secondary,
    .woocommerce-result-count,
    .woocommerce-ordering {
      display: none;
    }

    #primary {
      width: 100%;
    }

    .site-main {
      padding-top: 3em;
      padding-bottom: 2em;
    }

    .products {
      margin-top: 2em;
    }

    .category {
      &__title {
        text-transform: uppercase;
      }
    }
  }

  &.single-product {
    .price,
    .product_meta {
      display: none;
    }

    .site-main {
      padding-left: 15px;
      padding-right: 15px;
    }

    .product_title {
      font: 700 1.2rem/1.2 'Century Gothic', serif !important;
      color: var(--color__orange);
    }

    .woocommerce-product-gallery,
    .summary.entry-summary {
      float: none !important;
      width: 100% !important;
    }

    .wrappers {
      .wpb_single_image,
      .wpb_text_column {
        padding: 5px;

        > .wpb_wrapper {
          padding: 10px;
          border: 1px solid color(gray l(+30%));
          border-radius: 5px;
        }
      }

      .wpb_single_image .wpb_heading {
        font-size: 16px !important;
      }
    }

    .vc_section.col-5 {
      .wrappers {
        .wpb_column {
          width: calc(100% / 5);
          @media (--ipad) {
            width: calc(100% / 3);
          }
          @media (--ipad_mini) {
            width: calc(100% / 3);
          }
          @media (--phone) {
            width: 100%;
          }
        }
      }

      .wrappers.wrappers--6 {
        .wpb_column {
          width: calc(100% / 6);
          @media (--ipad) {
            width: calc(100% / 3);
          }
          @media (--ipad_mini) {
            width: calc(100% / 3);
          }
          @media (--phone) {
            width: 100%;
          }
        }
      }
    }
  }
}
