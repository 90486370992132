.site-footer {
  &__nav {
    @media (--phone){
      display: none;
    }

    .menu {
      list-style: none;
      text-align: right;

      &-item {
        display: inline-block;
        padding: 0 1.5em;
        text-transform: uppercase;

        &:last-child {
          padding-right: 0;
        }

        > a {
          color: white;
          font: 16px/1.2 'Century Gothic', serif;
        }
      }

      .sub-menu {
        display: none;
        visibility: hidden;
      }
    }
  }
}
