#mainnav {
  .menu-item {
    position: static;
    padding: 0 2em;
    text-transform: uppercase;

    &:last-child {
      padding-right: 0;
    }

    &:hover {
      > .sub-menu > li > a {
        padding: 0;
      }
    }

    > a {
      font-weight: bold;
    }

    &.current-product-ancestor,
    &.current-menu-ancestor,
    &.current-menu-parent,
    &.current-product-parent,
    &.current_page_parent,
    &.current_page_item,
    &.current-menu-item {
      > a {
        color: var(--color__orange);
      }
    }

    &-34 {
      &:hover {
        > .sub-menu {
          display: flex !important;
        }
      }

      > .sub-menu {
        display: none !important;
        flex-flow: row wrap;
        justify-content: flex-start;
        left: 0;
        right: 0;
        width: 100% !important;
        padding: 1em;
        background-color: var(--color__blue);

        &::after {
          display: none;
        }

        > li.menu-item {
          width: 25%;
          padding: 0 1em;

          + .menu-item {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              top: .5em;
              left: 0;
              width: 1px;
              height: 1em;
              background-color: white;
            }
          }

          > a {
            text-transform: uppercase;
          }
        }

        .menu-item {
          padding: 0;

          > a {
            float: none;
            padding: 0;
            background-color: transparent;
            width: 100% !important;
            border-top: none;
            text-transform: capitalize;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .sub-menu {
          position: static;
          visibility: visible;
          width: 100%;
          margin-top: .5em;
          background-color: transparent;
          opacity: 1;

          &::after {
            display: none;
          }

          .menu-item {
            float: none;
            width: 100%;
            padding: 5px 0;

            > a {
              font-weight: 400;
              font-size: 14px;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }
}
