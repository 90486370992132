.about__certificates {
  @media (--phone){
    margin-top: 3em;
  }

  > .vc_column-inner > .wpb_wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 90%;

    > .vc_row {
      width: calc(50% - 7px);
      margin-left: 0;
      margin-right: 0;

      &:first-child {
        margin-top: 30%;

        .wpb_single_image {
          width: 70%;
          margin-left: auto;

          &:last-child {
            width: 50%;
          }
        }
      }

      &:last-child {
        .wpb_single_image:last-child {
          width: 70%;
        }
      }
    }
  }

  .wpb_single_image {
    + .wpb_single_image {
      margin-top: 14px;
    }
  }
}
