.about__content {
  padding-top: 1em !important;

  &:before {
    content: 'ABOUT';
    position: absolute;
    right: 7%;
    bottom: 0;
    @mixin bigFont;
  }
}
