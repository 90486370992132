.services__img {
  overflow: hidden;
  height: 100%;
    
  .vc_figure,
  .vc_single_image-wrapper,
  .vc_single_image-img {
    width: 100%;
    height: 100%;
  }

  .vc_single_image-img {
    object-fit: cover;
    transition: transform .6s;
  }

  &:hover {
    .vc_single_image-img {
      transform: scale(1.1, 1.1);
    }
  }
}
