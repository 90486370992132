.services__content {
  > .vc_column-inner {
    height: 100%;

    > .wpb_wrapper {
      height: 100%;
      padding: 0 2em 2em 2em;
    }
  }

  .wpb_single_image {
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 10em;
      margin: 1em 0 1em .9em;
      background-color: var(--color__orange);
      @media (--ipad){
        height: 2em;
      }
      @media (--ipad_mini){
        height: 2em;
      }
      @media (--phone){
        height: 2em;
      }
    }
  }
}
