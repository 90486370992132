.banner__title {
  position: absolute;
  top: 50%;
  left: 10%;
  right: 10%;
  transform: translate(0, -50%);
  font-size: 2.5rem;
  
  > span {
    display: block;
    font-weight: 400;
    font-size: .4em;
  }
}
