.banner-video {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: color(gray a(.8));
  }

  video {
    display: block;
    width: 100%;
    filter: blur(6px);
    @media (--desktop){
      height: 100vh;
      object-fit: cover;
    }
  }
}
