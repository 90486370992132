.footer-widgets {
  position: relative;
  padding: 0;
  margin-bottom: -4em;

  #media_image-3 {
    margin-bottom: 0;
    padding: 2em 0 0 2em;
  }

  #custom_html-2 {
    padding: 0 0 2em 2em;
    margin-bottom: 0;
    font-size: 1rem;

    .widget-title {
      font-size: 1rem;
      color: var(--color__orange);

      &:before {
        content: '';
        display: block;
        width: 1px;
        height: 10em;
        margin: 1em 0 1em .5em;
        background-color: var(--color__orange);
      }
    }

    address {
      margin-bottom: 0;

      b {
        color: var(--color__orange);
      }
    }
  }

  #media_image-2 {
    overflow: hidden;
    margin-bottom: 0;

    &:hover {
      img {
        transform: scale(1.1, 1.1);
      }
    }

    img {
      width: 100%;
      transition: transform .6s;
    }
  }
}
