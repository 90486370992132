.btn {
  display: inline-block;
  background-color: var(--color__orange);
  border: 1px solid transparent;
  font: 12px/1.2 'Century Gothic', serif;
  color: var(--color__blue);

  &:hover {
    border-color: var(--color__orange);
    background-color: transparent;
    color: var(--color__orange);
  }
}
